$transitionDuration: .3s;
$transitionFunction: ease;

$header-height: 50px;

$nav-menu-header-column-width-large: 90px;

$navi-elem-height-standard: 40px;
$navi-elem-height-breakpoint-xl: 34px;

$navi-elem-height-image-row: 48px;

$navi-elem-height-emphasized: 48px;

$desktop-special-font-size: (font-size: 21px, line-height: 27px);

/*          */

/*                                                                  */
/*                                                                                     */
/*                                                                            */
/*                                             */

$nav-small-medium-width: 22.5em; /*                               */
/*                                                                               */
/*                                                                              */
$nav-medium-large-width: 53em; /*                               */
/*                                                                                    */
