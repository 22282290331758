@use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl;
@use "~@otto-ec/global-frontend-settings/scss/variables" as variables;
@use "~@otto-ec/global-frontend-settings/scss/grid" as grid;
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;
@use "../variables" as vars;
@use "../mixins" as mixins;

.nav_desktop-global-navigation--only-desktop {
    display: none !important;

    @include gfs.breakpoint(gfs.$breakpoint-l) {
        display: block !important;
    }
}

.nav_desktop-global-navigation {
  position: relative;
  overflow-x: visible;
  width: 100%;
  height: auto;
  user-select: none;
  padding: 0;

  z-index: 11;
  background-color: white;

  &__content {
    padding: 10px 0 8px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: 0;
    list-style: none;

    margin-right: 16px;
    margin-left: 16px;

    & .nav_desktop-global-navigation-separator,
    & .nav_desktop-global-navigation-item-element {
      padding: 0;
      width: auto;
      display: inline-block;
      flex: 0 0 auto;
    }
  }
}

.nav_desktop-global-navigation-item {
  $self: &;

  display: inline-block;
  position: relative;
  padding: 0;
  color: pl.$pl_color-grey400;

  font-size: map-get(variables.$font125, font-size);
  line-height: 23px;

  font-weight: normal;
  height: auto;
  border: none;
  overflow: visible;

  background-color: transparent;
  cursor: pointer;
  white-space: nowrap;

  @include gfs.breakpoint-raw(vars.$nav-medium-large-width) {
    font-size: map-get(variables.$font150, font-size);
    line-height: 24px;
  }

  @include gfs.breakpoint(gfs.$breakpoint-xl) {
    font-size: map-get(vars.$desktop-special-font-size, font-size);
    line-height: 27px;
  }

  &__title {
    color: #777777;
    padding-right: 0;
    display: inline-flex;
    flex-flow: wrap;
  }

  &__tab {
    display: none;
  }

  &--active {

    /*                                                               */
    /*                                                           */
    /*                                                           */
    /*                         */

    #{$self}__tab,
      /*          */
    &::after {
      display: block;
      position: absolute;
      height: 68px;
      top: -2px;
      left: -9px;
    }

    #{$self}__tab {
      padding: 1px 8px;

      color: pl.$pl_color-red100;
      background-color: pl.$pl_color-grey25;

      border: 1px solid pl.$pl_color-grey200;
      border-radius: 3px 3px 0 0;
      border-bottom: 0;

      /*                */
      z-index: 1000;
      display: block;
    }

    /*          */
    &::after {
      content: "";

      box-sizing: content-box;
      width: 100%;
      padding: 0 9px;

      @include mixins.flyout-shadow;
    }
  }

  &--active#{&}--row-2 {
    #{$self}__tab,
      /*         */
    &::after {
      height: 41px;
      @include gfs.breakpoint-raw(vars.$nav-medium-large-width) {
        height: 38px;
      }
      @include gfs.breakpoint(gfs.$breakpoint-xl) {
        height: 36px;
      }
    }
  }
}

.nav_desktop-global-navigation-separator {
  &:after {
    color: pl.$pl_color-grey400;
    @include mixins.font-size(variables.$font125);
    content: attr(data-dot);

    @include gfs.breakpoint-raw(vars.$nav-medium-large-width) {
      @include mixins.font-size(variables.$font150);
    }

    @include gfs.breakpoint(gfs.$breakpoint-xl) {
      @include mixins.font-size(vars.$desktop-special-font-size);
    }
  }

  &--break, &--end {
    display: inline-block;
    width: 100% !important;

    &:after {
      display: inline-block;
      height: 0;
      content: '';
    }
  }

  &--break {
    height: 4px;

    @include gfs.breakpoint-raw(vars.$nav-medium-large-width) {
      height: 6px;
    }

    @include gfs.breakpoint(gfs.$breakpoint-xl) {
      height: 5px;
    }
  }

  &--end {
    height: 5px;

    @include gfs.breakpoint-raw(vars.$nav-medium-large-width) {
      height: 5px;
    }

    @include gfs.breakpoint(gfs.$breakpoint-xl) {
      height: 6px;
    }
  }
}
