$breakpoint-s: "s";
$breakpoint-m: "m";
$breakpoint-l: "l";
$breakpoint-xl: "xl";

$breakpoints: (
  $breakpoint-s: 22.5em,
  $breakpoint-m: 28em,
  $breakpoint-l: 48em,
  $breakpoint-xl: 62em,
);

///
/*                                                                  */
/*                                             */
///
@mixin breakpoint-raw($from, $to: false) {
  @if $to {
    @media (min-width: $from) and (max-width: $to) {
      @content;
    }
  } @else {
    @media (min-width: $from) {
      @content;
    }
  }
}

///
/*                                                                                 */
/*                                                */
///
/*                                                 */
/*                                                          */
///
@mixin breakpoint($from, $to: false) {
  $mapGetFrom: map-get($breakpoints, $from);

  @if $mapGetFrom == null {
    @error 'Breakpoint #{$from}: not found';
  }

  @if $to {
    $mapGetTo: map-get($breakpoints, $to);
    @if $mapGetTo == null {
      @error 'Breakpoint #{$to}: not found';
    }
    /*                                   */
    /*                                                              */
    @include breakpoint-raw($mapGetFrom, $mapGetTo - 0.01em) {
      @content;
    }
  } @else {
    @include breakpoint-raw($mapGetFrom) {
      @content;
    }
  }
}

///
/*                                                 */
/*                                                                                     */
///
@mixin from-s($to: false) {
  @include breakpoint($breakpoint-s, $to) {
    @content;
  }
}

///
/*                                                 */
/*                                                                                     */
///
@mixin from-m($to: false) {
  @include breakpoint($breakpoint-m, $to) {
    @content;
  }
}

///
/*                                                 */
/*                                                                                     */
///
@mixin from-l($to: false) {
  @include breakpoint($breakpoint-l, $to) {
    @content;
  }
}

///
/*                                                  */
///
@mixin from-xl() {
  @include breakpoint($breakpoint-xl) {
    @content;
  }
}
