@use "~@otto-ec/global-frontend-settings/scss/pl_variables" as pl;
@use "../variables" as vars;
@use "../mixins" as mixins;

nav-desktop-top-level-item::part(navFlyout) {
  position: absolute;
  transition: none;
  width: 100%;
  left: 0;
  top: 75px;
  overflow-x: visible;
  height: auto;
  line-height: unset;
  list-style: unset;
}

nav-desktop-top-level-item::part(navFlyoutPanel) {
  height: auto;
  width: auto;
  display: block;
  overflow-y: visible;
  padding-bottom: vars.$header-height;
  background-color: pl.$pl_color-grey25;
  @include mixins.flyout-shadow;
  border: 1px solid pl.$pl_color-grey200;
  border-radius: 0 0 3px 3px;
}