@use "sass:math";

/*                                                                 */
/*                                                                                    */
///
/*                  */
/*                                              */
/*                                    */
/*                                             */
@mixin font-size($font, $line-height: true) {
  font-size: (map-get($font, font-size));
  font-size: (math.div(map-get($font, font-size), 16px) + rem);
  @if $line-height == true and map-get($font, line-height) != "" {
    line-height: math.div(map-get($font, line-height), map-get($font, font-size)) + em;
  }
}

@mixin flyout-shadow {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
